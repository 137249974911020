import { useState, useEffect } from "react";
import React from 'react'
import {Container,Row, Col} from "react-bootstrap"
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import headerImg from "../assets/img/header-img.svg"
const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const [index, setIndex] = useState(1);
    const toRotate = [ "Web Developer", "IT engineer", "Web designer" ];
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
          tick();
        }, delta);
    
        return () => { clearInterval(ticker) };
      }, [text])
    
      const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
    
        setText(updatedText);
    
        if (isDeleting) {
          setDelta(prevDelta => prevDelta / 2);
        }
    
        if (!isDeleting && updatedText === fullText) {
          setIsDeleting(true);
          setIndex(prevIndex => prevIndex - 1);
          setDelta(period);
        } else if (isDeleting && updatedText === '') {
          setIsDeleting(false);
          setLoopNum(loopNum + 1);
          setIndex(1);
          setDelta(500);
        } else {
          setIndex(prevIndex => prevIndex + 1);
        }
      }

  return (
    <section className="banner" id='home'>
<Container>
    <Row className="align-items-center">
       <Col xs={12} md={6} xl={7}>
       <TrackVisibility>
       {({ isVisible }) =>
       <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
        <span className="tagline">Welcome to my Portfolio</span>
        <h1>{'Hi I am Sachin Jaiswal'} <span className="wrap">{text}</span>  </h1>
        
        
        <p>Hello My name is Sachin Jaiswal.I am a Web developer/IT engineer based in Winnipeg,Canada</p>
       <a target="_blank " href="https://docs.google.com/document/d/1DXZ8uaqvwU2GvO0WaoL8wm1biSj0-tj8pqvGKQ0RfDE/edit?usp=sharing"> <button id="banner-button" onClick={()=>{console.log('connect')}}>DownLoad Resume< ArrowRightCircle size={25}/> </button></a>
        </div>}
        </TrackVisibility>
       </Col>

       <Col xs={12} md={6} xl={5} >
        <img src={headerImg} alt="Header img" />
       </Col>
    </Row>
</Container>
    </section>
  )
}

export default Banner