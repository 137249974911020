import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import { useRef } from 'react'
import emailjs, { sendForm } from '@emailjs/browser';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  const [username, setUsername] = useState("")
  const [subject, setSubject] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [submit, setSubmit] = useState("Submit")
  const uhandleClick = (event) => {
    const username = event.target.value
    const subject = event.target.value





    setUsername(username)




    console.log(subject)




  }
  const shandleClick = (event) => {
    const subject = event.target.value
    setSubject(subject)
  }
  const mhandleClick = (event) => {
    const message = event.target.value
    setMessage(message)
  }
  const ehandleClick = (event) => {
    const email = event.target.value
    setEmail(email)
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yzf7vvp', 'template_ihxfdio', e.target, 'FLZi8hhkZWsv5IOcY')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  const handleSubmit = () => {

    setSubmit("Your response has been submitted")


  }

  const resetButton = () => {
    setUsername("")
    setSubject("")
    setEmail("")
    setMessage("")
    setSubmit("Submit")
  }





  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Get In Touch</h2>
                  <form classvalues="form" onSubmit={sendEmail}>
                    <Row>
                      <Col size={12} sm={12} className="px-1">
                        <input value={username} type="text" placeholder='Name' name="username" onChange={uhandleClick} required />
                      </Col>
                      <Col size={12} sm={12} className="px-1">
                        <input value={subject} type="text" placeholder='Subject' name="usersubject" onChange={shandleClick} />
                      </Col>
                      <Col size={12} sm={12} className="px-1">
                        <input value={email} type="email" placeholder='Email' name="useremail" onChange={ehandleClick}  required />
                      </Col>
                      <Col size={12} sm={12} className="px-1">
                        <textarea value={message} rows="5" placeholder='message' name="usermessage" onChange={mhandleClick} />
                      </Col>
                      <Col>
                        <button type='submit' onClick={handleSubmit} >{submit}</button>
                        <button type="reset" onClick={resetButton}>Reset Form</button>
                      </Col>

                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}