import React from 'react'
import Vue from "../assets/img/vue-logo.png";
import mongo from "../assets/img/mongo.png";
import aws from "../assets/img/aws.png";
import react from "../assets/img/react.png";
import Carousel from 'react-multi-carousel';
import node from "../assets/img/node.png";
import bootstrap from "../assets/img/bootstrap.png";
import colorSharp from "../assets/img/color-sharp.png"
import 'react-multi-carousel/lib/styles.css';
import {Container,Row, Col} from "react-bootstrap"
const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <section className="skill" id='skills'>
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <h2>
                                Skills
                            </h2>
                            <p> My Skills include knowledge of programming languages/frameworks like REACT js,Vue js, Node js ,Express js,MongoDB,Core Java,Bootsrap,HTML,CSS,github and knowledge of IT tools like jamf,intune,okta SSO,JIRA,GSUITE,Active Directory etc  </p>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                <div className="item">

                                    <img src={react} alt="" />
                                    <h5>Html,CSS and javascript(react)</h5>
                                </div>
                                <div className="item">

                                    <img src={Vue} alt="" />
                                    <h5>Vue js</h5>
                                </div>
                                <div className="item">

                                    <img src={node} alt="" />
                                    <h5>Express js and node js</h5>
                                </div>
                                <div className="item">

                                    <img src={mongo} alt="" />
                                    <h5>Mongo DB database</h5>
                                </div>
                                <div className="item">

                                    <img src={aws} alt="" />
                                    <h5>AWS</h5>
                                </div>
                                <div className="item">

                                    <img src={bootstrap} alt="" />
                                    <h5>Bootstrap</h5>
                                </div>

                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp} alt="Image" />
        </section>
    )
}

export default Skills