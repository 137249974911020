import { Container, Row, Col } from "react-bootstrap";

import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center justify-content-center">
          
          
          <Col size={12} sm={6} className="text-center " style={{"margin-top":"50px"}}>
            <div className="social-icon">
              <a href="https://linkedin.com/in/sachinjaiswal981" target="_blank"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://facebook.com/in/sachin.jaiswal.524" target="_blank"><img src={navIcon2} alt="Icon" /></a>
              <a href="https://instagram.com/__essjay" target="_blank"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}