
import './App.css';
import NavBar2 from './Components/NavBar2';

import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from './Components/Banner';
import Skills from './Components/Skills';
import { Projects } from './Components/Projects';
import { Contact } from './Components/Contact';
import { Footer } from './Components/Footer';

function App() {
  return (
    <div className="App">
   <NavBar2/>
   <Banner/>
   <Skills/>
   <Projects/>
   <Contact/>
   <Footer/>
    </div>
    
  );
}

export default App;
