import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useState,useEffect} from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { HashLink } from 'react-router-hash-link';
import {
    BrowserRouter as Router,Link
  } from "react-router-dom";
  import logo from '../assets/img/logo.svg';
  import githubIcon from '../assets/img/github-icon.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
const NavBar2 = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const onScroll = () => {
          if (window.scrollY > 50) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
    
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
      }, [])
    
      const onUpdateActiveLink = (value) => {
        setActiveLink(value);
      }
  return (
    <Router>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className={scrolled ? "scrolled" : ""}>
            <Container>
                <Navbar.Brand href="#home">
                <h1>SJ</h1>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                        <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                        <Nav.Link href="#project" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>

                    </Nav>
                    <span className="navbar-text">
                        <div className="social-icon">
                            <Link to={{ pathname: "https://linkedin.com/in/sachinjaiswal981"}} target="_blank"><img src={navIcon1} alt="hh" /></Link>
                            <Link to={{ pathname: "https://github.com/sachinj56"}} target="_blank"><img src={githubIcon} alt="hh" /></Link>
                            <Link to={{ pathname: "https://instagram.com/__essjay"}} target="_blank"><img src={navIcon3} alt="hh" /></Link>
                            
                        </div>
                    <Nav.Link href="#connect" >
                        <button className="vvd"><span>Let’s Connect</span></button>
                        </Nav.Link>
                    </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </Router>
  )
}

export default NavBar2